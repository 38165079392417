<template>
  <div class="container">
    <a href="javascript:history.back()" class="btn btn-primary btn-block">Volver</a>
    <h1 class="text-center mt-2">Política de cookies</h1>

    <h4>Concepto de Cookies</h4>    <p>
      Las cookies constituyen una herramienta empleada por los servidores Web
      para almacenar y recuperar información acerca de sus visitantes o
      usuarios.
    </p>

    <p>
      No es más que un fichero de texto que algunos servidores instalan durante
      la navegación y que permiten conocer información como por ejemplo: el
      lugar desde el que accede, el tiempo de conexión, el dispositivo desde el
      que accede (fijo o móvil), el sistema operativo y navegador utilizados,
      las páginas más visitadas, el número de clics realizados y de datos
      respecto al comportamiento del usuario en Internet.
    </p>
    <p>
      No proporcionan referencias que permitan deducir el nombre y apellidos del
      usuario y no pueden leer datos de su disco duro ni incluir virus en sus
      equipos.
    </p>
    <p>
      Asimismo, no puede leer las cookies implantadas en el disco duro del
      usuario desde otros servidores.
    </p>
    <h4>¿Por qué son importantes?</h4>
    <p>
      El sitio web es accesible sin necesidad de que las cookies estén
      activadas, si bien, su desactivación puede impedir su funcionamiento
      correcto.
    </p>
    <p>
      Desde un punto de vista técnico las cookies permiten que los sitios web
      funcionen de forma más ágil y adaptada a las preferencias de los usuarios,
      como por ejemplo almacenar el idioma, la moneda del país o detectar el
      dispositivo de acceso.
    </p>
    <p>
      Establecen niveles de protección y seguridad que Impiden o dificultan
      ciberataques contra el sitio web o sus usuarios.
    </p>
    <p>
      Permiten que los gestores de los medios puedan conocer datos estadísticos
      recopilados para mejorar la calidad y experiencia de sus servicios.
    </p>
    <p>
      Sirven para optimizar la publicidad que mostramos a los usuarios,
      ofreciendo la que más se ajusta a sus intereses.
    </p>

    <h4>Autorización para el uso de cookies</h4>

    <p>
      En el sitio web aparece un Aviso de Cookies que el usuario o visitante
      puede aceptar, consintiendo expresamente al uso de las cookies según se
      indica a continuación.
    </p>
    <p>
      El usuario puede configurar su navegador para rechazar por defecto todas
      las cookies o para recibir un aviso en pantalla de la recepción de cada
      cookie y decidir sobre su instalación o no en su disco duro.
    </p>
    <h4>Configuración del navegador</h4>
    <p>
      CAV VENTANCAT 2010 SL <a href="https://app.ventancatgrup.com">(https://app.ventancatgrup.com/login)</a> recuerda a sus
      usuarios que el uso de cookies podrá estar sujeto a su aceptación durante
      la instalación o actualización del navegador utilizado por éstos.
    </p>

    <p>
      Esta aceptación podrá ser revocada mediante las opciones de configuración
      de contenidos y privacidad disponibles en el mismo. El Titular recomienda
      a sus usuarios que consulten la ayuda de su navegador o acceda a las
      páginas web de ayuda de los principales navegadores: Firefox, Internet
      Explorer, Safari, Chrome.
    </p>
    <p>
      Si desea más información acerca de cómo revocar el consentimiento prestado
      o sobre el procedimiento para deshabilitar las cookies, así como realizar
      alguna pregunta sobre la Política de Cookies, puede contactar con el
      titular a través de la siguiente dirección de correo electrónico a
      <a href="mailto:fmoreno@ventancatgrup.com">fmoreno@ventancatgrup.com</a> indicando “Política de Cookies” en el asunto.
    </p>
    <h4>Tipos de Cookies que se utilizan en la Web</h4>
    <p>
      El usuario que navega por la Web puede encontrar cookies insertadas
      directamente por el titular, o bien cookies insertadas por entidades
      distintas al titular, según lo detallado en los siguientes apartados:
    </p>
    (indicar los tipos de cookies empleadas)
    <p>
      <strong>* Cookies de sesión</strong> , expiran cuando el usuario abandona
      la página o cierra el navegador, es decir, están activas mientras dura la
      visita al sitio web y por tanto son borradas de nuestro ordenador al
      abandonarlo.
    </p>
    <p>
      <strong>* Cookies permanentes</strong>, expiran cuando se cumple el
      objetivo para el que sirven o bien cuando se borran manualmente, tienen
      fecha de borrado y se utilizan normalmente en proceso de compra online,
      personalizaciones o en el registro, para no tener que introducir una
      contraseña constantemente.
    </p>
    Según la entidad que gestiona el equipo o dominio desde donde se envían las
    cookies y trata los datos que se obtengan, podemos distinguir entre Cookies
    propias y de terceros.
    <p>
      <strong>* Cookies propias</strong> son aquellas cookies que son enviadas
      al ordenador del usuario y son gestionadas exclusivamente por nosotros
      para el mejor funcionamiento del Sitio Web. La información que recabamos
      se emplea para mejorar la calidad de nuestro servicio y tu experiencia
      como usuario.
    </p>

    <p>
      <strong>* Cookies de terceros</strong>, cuando el usuario interactúa con
      el contenido de nuestro sitio Web también pueden establecerse cookies de
      terceros (por ejemplo, al pulsar botones de redes sociales o visionar
      vídeos alojados en otro sitio web), que son aquellas establecidas por un
      dominio diferente de nuestro Sitio Web. No podemos acceder a los datos
      almacenados en las cookies de otros sitios web cuando navegues en los
      citados sitios web.
    </p>

    <p>
      Navegar por este portal web supone que se puedan instalar los siguientes
      tipos de Cookies:
    </p>
    <b-img src="@/assets/images/pages/fotoCookies.jpg" fluid alt="cookies"></b-img>
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss">
  @import "@core/scss/vue/pages/page-pricing.scss";
  .container{
    margin-top: 40px;
    padding: 40px;
    text-align: justify;
  }
  h1{
    padding-bottom: 20px;
  }
</style>